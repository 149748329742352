/* GameSelection.css */

@font-face {
  font-family: 'slkscr';
  src: url('./Fonts/slkscr.ttf') format('woff2');
  /* You can add additional formats if needed */
  font-weight: normal;
  font-style: normal;
}

.game-selection-container {
    padding-top: 10vh;
    font-family: 'slkscr', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: #60006c;
    text-align: center;
  }
  
  .game-selection-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: 600;
  }
  
  .button-group {
    display: flex;
    gap: 20px; /* Spacing between buttons */
  }
  
  .game-button {
    font-family: 'slkscr', sans-serif;
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #0f3460;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .game-button:hover {
    background-color: #7158a2;
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
  
  .desktop-button {
    background-color: #138c41;
  }
  
  .mobile-button {
    background-color: #138c41;
  }
  
  .desktop-button:hover {
    background-color: #7158a2;
  }
  
  .mobile-button:hover {
    background-color: #7158a2;
  }
  
  .game-button:active {
    transform: translateY(0); /* Reset lift effect on click */
  }
  
  @media (max-width: 768px) {
    .game-selection-title {
      font-size: 2rem;
    }
  
    .game-button {
      padding: 12px 25px;
      font-size: 1rem;
    }
  }
  