

.FullScreenButton {
    font-family: 'slkscr', sans-serif;
    justify-content: center;
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #0f3460;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.FullScreenButton:hover {
    background-color: #7158a2;
    transform: translateY(-2px);
}


.BuyMeCoffee {
    display: flex;
    flex-direction: column;
    margin: 5vh;
}

.BuyCoffeeText {
    margin: 2vh;

}