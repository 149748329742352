/* BlogPost.css */

.BlogPost {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Apply the same style as the preview */
.BlogEntry {
    width: 90%;
    max-width: 800px;
    border: 4px solid #ffe1fd;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 225, 253, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.BlogTitle {
    font-family: 'slkscr', sans-serif;
    color: rgb(78, 21, 78);
    font-size: 32px;
    margin-bottom: 10px;
}

.AuthorName {
    margin-top: 10vh;
    font-family: 'slkscr', sans-serif;
    font-size: 2vw;
    font-weight: bold;
}

/* Media query for mobile devices */
@media (max-width: 768px) {  /* 768px is a common breakpoint for tablets and mobile devices */
    .AuthorName {
        font-size: 5vw;  /* Adjust the font size for smaller screens */
    }
}

.BlogContent {
    font-family: 'slkscr', sans-serif;
    line-height: 1.8;
    margin-bottom: 20px;
    font-size: 18px;
}

.BlogDate {
    font-family: 'slkscr', sans-serif;
    color: #444;
    font-size: 150%;
    text-align: right;
    margin-top: 20px;
}

/* Additional optional styling for the post page */
.BlogPost .BlogTitle {
    font-size: 32px; /* Slightly larger title for the full post */
}

.BlogPost .BlogContent {
    font-size: 18px; /* Slightly larger content for readability */
}

.BlogPost .BlogDate {
    margin-top: 20px;
    font-size: 16px;
    text-align: right;
    color: #555;
}
