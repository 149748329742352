/* Center the container to take full screen dimensions */
.NeonHorizonContainer {
    transform: translatey(10vh);
    display: flex;                   /* Use flexbox for centering */
    justify-content: center;         /* Center horizontally */
    align-items: center;             /* Center vertically */
    height: 100vh;                   /* Full height of the viewport */
    width: 100vw;                    /* Full width of the viewport */
    flex-direction: column;          /* Arrange items vertically */
    text-align: center;              /* Center text inside the container */
  }
  
  /* Style the iframe */
  .NeonHorizonWindow {
    width: 400px;                     /* Responsive width: 70% of the viewport width */
    height: 70vh;                    /* Responsive height: 70% of the viewport height */
    border-color: purple;                    /* Remove default iframe border */
    border-width: 5px;
  }
  
  /* Style the offer container */
  .OfferContainer {
    margin-top: 10vh;
    font-family: 'slkscr', sans-serif;
    width: 90%;
    max-width: 800px;
    border: 4px solid #ffe1fd;
    border-radius: 10px;
    background-color: rgba(255, 225, 253, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Style the offer link */
  .OfferLink {
    text-decoration: none;
    color: blue;
    font-size: 20px;
  }
  

  .GameTitle {
    font-size: 50px;
    color: rgb(82, 25, 135);
    font-family: 'slkscr';
  }


  .FullScreenButton {
    transform: translateY(5vh);
    font-family: 'slkscr', sans-serif;
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #0f3460;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.FullScreenButton:hover {
    background-color: #7158a2;
    transform: translateY(-2px);
}



.BuyCoffee {
  display: flex;
  flex-direction: column;
  transform: translateY(15vh);
}

.CoffeeText {
  margin: 2vh
}