/* src/App.css */
.testerSignUpSection {
    width: 80vw;
    background-color: rgba(255, 225, 253, 0.4);
    padding: 20px;
    border: 4px solid #ffe1fd;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
  }
  
  .testerSignUpSection .becomeText {
    font-family: 'slkscr', sans-serif;
    color: rgb(33, 129, 60);
    margin-top: 0;
  }

  .testerSignUpSection h3 {
    font-family: 'slkscr', sans-serif;
    color: rgb(33, 129, 60);
  }
  
  .signUpForm {
    background-color: rgb(111, 171, 100);
  }

  .signUpForm .formGroup {
    margin-bottom: 15px;
  }
  
  .signUpForm .submitButton {
    background-color: rgb(53, 223, 53);
    font-family: 'slkscr', sans-serif;
    color: rgb(6, 86, 24);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signUpForm .submitButton:hover {
    background-color: #16511a;
  }
  