/* Main container for the SpaceBreaker component */


/* Game iframe styling */
.SpaceBreakerWindow {
  transform: translateY(15vh);
  width: 100%; /* Makes the iframe responsive to the width of the container */
  height: auto; /* Automatically adjusts height based on the width and aspect ratio */
  aspect-ratio: 16 / 9; /* Maintains the aspect ratio */
  border: none; /* Removes any border */
  max-width: 100%; /* Prevents exceeding the screen width */
  max-height: 80vh; /* Prevents exceeding the screen height */
  flex-shrink: 0;
  display: block;
  box-sizing: border-box; /* Ensures padding and border are included in total width/height */
}

/* Game title styling */
.GameTitle {
  transform: translateY(16vh);
  font-size: 2rem;
  color: #fff;
  margin-top: 10px;
  text-align: center;
}

/* Full-screen button styling */
.FullScreenButton {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.FullScreenButton:hover {
  background-color: #555;
}

/* Container for the coffee support section */
.BuyCoffee {
  margin-top: 20px;
  text-align: center;
}

.CoffeeText {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 10px;
}

