
.GamesPage {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .PageTitle {
    border: 4px solid #ffe1fd;
    background-color: rgb(207, 173, 207, 0.4);
    padding: 10px;
    border-radius: 10px;
    font-family: 'slkscr', sans-serif;
    text-align: center;
    margin-top: 200px;
    font-size: 3em;
    font-weight: bold;
    color: rgb(78, 21, 78);
  }
  
  .GameSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures vertical space between items */
    width: 80vw;
    max-width: 800px;
    margin-bottom: 40px;
    border: 4px solid #ffe1fd;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 225, 253, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  }

  .GameSection:first-of-type {
    margin-top: 20px; /* Adjust this value to ensure enough space below the navbar */
  }
  
  .GameTitle {
    font-family: 'slkscr', sans-serif;
    color: rgb(78, 21, 78);
    margin-bottom: 20vh;
  }
  
  .GameDescription {
    margin: 10px 0;
    line-height: 1.6;
    font-family: 'slkscr', sans-serif;
  }
  
  .Screenshots {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow-x: auto;
    padding: 10px 0;
  }
  
  .Screenshot {
    margin-right: 10px;
    border-radius: 5px;
    height: 150px; /* Adjust as needed */
  }
  
  .PlayStoreLink {
    display: inline-block;
    margin-top: 20px;
  }

  .ButtonContainer {
    display: flex;          /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center;    /* Center align items */
    gap: 15px;              /* Optional: Add space between the buttons */
    margin: 20px 0;         /* Optional: Add margin around the container */
  }
  
  .PlayStoreButton {
    background-color: #3cba54; /* Google Play green color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'slkscr', sans-serif;
    transition: background-color 0.3s;
  }

  .PlayOnlineButton {
    background-color: #3cba54; /* Google Play green color */
    color: white;
    width: 30vw;
    padding: 10px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'slkscr', sans-serif;
    transition: background-color 0.3s;
  }
  
  .PlayStoreButton:hover {
    background-color: #7158a2;
    transform: translateY(-2px); 
  }

  .PlayOnlineButton:hover {
    background-color: #7158a2;
    transform: translateY(-2px);
  }


  .tagContainer {
    color: rgb(88, 66, 94);
  }

  .AdContainer {
    display: flex;
    justify-content: center;
  }
  
  .GamesPage .GameSection {
    margin: 0 auto;
  }