/* QuizData.css */
.quiz-container {
    display: flex;
    flex-direction: column;
    transform: translateY(200px);
    max-width: 800px;
    margin: auto;
    margin-bottom: 10vh;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: rgb(207, 173, 207, 0.4);
}



.quiz-header {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Base styling for the option block */
.quiz-option {
    display: block;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(207, 173, 207, 0.6);
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

/* Highlight the option when hovered */
.quiz-option:hover {
    background-color: rgba(206, 106, 206, 0.8);
}

/* Highlight the entire block when selected */
.quiz-option.selected {
    background-color: #d0e8ff; /* Change this to your desired color */
    border-color: #007bff; /* Change this to your desired border color */
}

/* Hide the default radio button */
.quiz-option input[type="radio"] {
    display: none;
}


.quiz-button {
    padding: 10px 20px;
    margin-bottom: 3vh;
    background-color: #9d00ff;
    color: white;
    border: 4px solid #ffe1fd;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-button:hover {
    transform: translateY(1vh);
    padding: 10px 20px;
    margin-bottom: 3vh;
    background-color: #b23ef9;
    color: white;
    border: 4px solid #ffe1fd;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.quiz-result {
    transform: translateY(100px);
    font-weight: bold;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    color: rgb(246, 229, 255);
}

/* Share button styling to match the aesthetic of the quiz */
.quiz-share-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #7a00e6; /* A color similar but slightly different from the next button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Share button hover effect for better user experience */
.quiz-share-button:hover {
    background-color: #9d00ff; /* Match the 'Next' button hover effect */
}

.buttons-container {
    display: flex;
    flex-direction: column;
}

.more-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #3582ff; /* A color similar but slightly different from the next button */
    color: white;
    border: 4px solid #ffe1fd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;   
}

.more-button:hover {
    transform: translateY(1vh);
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #79abfa; /* A color similar but slightly different from the next button */
    color: white;
    border: 4px solid #ffe1fd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;   
}