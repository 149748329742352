/* AboutStyles.css */

.AboutPageSection {
    transform: translateY(15vh);
    width: 90vw;
    padding: 20px 20px 20px;
    margin: auto; /* Center the section and add margin to the top and bottom */
    border: 4px solid #ffe1fd;
    border-radius: 10px;
    background-color: rgba(255, 225, 253, 0.3);
  }
  
  .AboutTitle {
    font-family: 'slkscr', sans-serif;
    color: rgb(78, 21, 78);
  }
  
  .AboutDescription {
    margin: auto;
    padding-bottom: 5vh;
    line-height: 1.6;
    font-family: 'slkscr', sans-serif;
  }
  