.quiz-list-container {
    transform: translateY(100px);
    max-width: 800px;
    margin: auto;
    padding: 20px;
    color: rgb(246, 229, 255);
}

.quiz-list {
    list-style: none;
    padding: 0;
}

.quiz-item {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 15px;
    border: 5px solid #ccc;
    border-radius: 8px;
    background-color: rgb(207, 173, 207, 0.4);
    transition: background-color 0.3s;
}

.quiz-item:hover {
    background-color: rgb(207, 173, 207, 0.7);
}

.quiz-link {
    text-decoration: none;
    color: inherit;
}
