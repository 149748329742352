.BlogPage {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BlogEntry {
    width: 90%;
    max-width: 800px;
    margin-bottom: 40px;
    margin-top: 100px;
    border: 4px solid #ffe1fd;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 225, 253, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.BlogTitle {
    font-family: 'slkscr', sans-serif;
    color: rgb(78, 21, 78);
    font-size: 24px;
    margin-bottom: 10px;
}

.AuthorName {
    margin-top: 10vh;
    font-family: 'slkscr', sans-serif;
    font-size: 2vw;
    font-weight: bold;
}

/* Media query for mobile devices */
@media (max-width: 768px) {  /* 768px is a common breakpoint for tablets and mobile devices */
    .AuthorName {
        font-size: 5vw;  /* Increase the font size for smaller screens */
    }
}

/* Optional: Further adjustments for even smaller screens */
@media (max-width: 480px) {  /* 480px is a common breakpoint for smaller mobile devices */
    .AuthorName {
        font-size: 5vw;  /* Increase the font size for smaller screens */
    }
}

.BlogContent {
    font-family: 'slkscr', sans-serif;
    line-height: 1.8;
    margin-bottom: 20px;
}

.BlogDate {
    font-family: 'slkscr', sans-serif;
    color: #444;
    font-size: 150%;
    text-align: right;
}


.DirectLinkAd {
    font-family: 'slkscr', sans-serif;
    width: 90%;
    max-width: 800px;
    margin-bottom: 40px;
    margin-top: 100px;
    border: 4px solid #ffe1fd;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 225, 253, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.BannerContainer {
    border: 5px solid #130b13;
    height: 200px;
    width: 200px;
}