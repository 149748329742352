/* Prevent side scrolling */
html, body {
  background: 
    linear-gradient(to bottom left, rgb(230, 38, 255), transparent),
    linear-gradient(to bottom right, blue, transparent),
    linear-gradient(to top left, green, transparent),
    linear-gradient(to top right, rgb(6, 143, 86), transparent);
  background-blend-mode: screen;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure padding and borders are included in element's width and height */
  min-height: 100vh; 
}

.footer {
  text-align: center;
  margin-top: 25vh;
  padding: 1em 0;
  background-color: rgba(138, 107, 173, 0.3);
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-text {
  margin: 0;
  color: rgb(238, 200, 241);
  font-size: small;
  font-weight: bold;
}

/* Apply box-sizing globally */
*, *::before, *::after {
  box-sizing: inherit;
}


.App {
  text-align: center;
}

.nav-bar {
  width: 100%;
  z-index: 2;
  position: fixed;
  display: 'flex';
  justify-content: 'flex-start';
  align-items: 'center';
  padding: '10px';
  background-color: 'black';
}

.devName {
  color: rgb(238, 200, 241);
  margin-left: 50px;
  font-size: 2vw;
}

@media (max-width: 1200px) {
  .devName {
    font-size: 2vw; /* Adjust for larger screens */
  }
}

@media (max-width: 768px) {
  .devName {
    font-size: 3vw; /* Adjust for tablets */
  }
}

@media (max-width: 480px) {
  .devName {
    font-size: 5vw; /* Adjust for mobile phones */
  }
}

.Nav-logo {
  max-height: 50px; /* Set the maximum height for the logo */
  height: auto; /* Maintain aspect ratio */
  width: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure it doesn't overflow its container */
}

.nav-bar ul {
  display: flex;
  list-style-type: none; /* Remove bullets */
  margin: 0;
  padding: 0;
}

.nav-bar a {
  color: white; /* White text */
  text-decoration: none; /* Remove underline */
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
 /* background-color: rgb(68, 121, 68); 
 */
}

.App-logo {
  height: 80vh;
  pointer-events: none;
  margin-bottom: -15vh;
  
}

@font-face {
  font-family: 'slkscr';
  src: url('./Fonts/slkscr.ttf') format('woff2');
  /* You can add additional formats if needed */
  font-weight: normal;
  font-style: normal;
}

.AboutSection {
  width: 80vw;
  background-color: rgba(255, 225, 253, 0.3); 
  font-family: 'slkscr', sans-serif;
  color: rgb(78, 21, 78);
  border: 4px solid #ffe1fd; /* Border around the section */
  padding: 100px 20px 20px;
  margin: 20px;
  border-radius: 10px; /* Optional: rounded corners */
}

.AboutUs {
  margin: 0;
  margin-top: -80px;
  margin-bottom: 10px;
  padding: 0;
}

.AboutSection p {
  margin: 10px 0 0 0; /* Adjust margins as needed */
  line-height: 1.6;
}

.buttonContainer {
  margin-top: 20px;
}

.homeButton {
  padding: 20px 20px;
  font-size: 5vw;
  margin: 5px;
  background-color: rgb(17, 57, 12);
  color: rgb(161, 208, 155);
  border-width: 3px;
  border-color: black;
  cursor: pointer;
  border-radius: 20px;
}

.gameButton {
  padding: 20px 20px;
  font-size: 5vw;
  margin: 5px;
  background-color: rgb(17, 57, 12);
  color: rgb(161, 208, 155);
  border-width: 3px;
  border-color: black;
  cursor: pointer;
  border-radius: 20px;
}


.textContainer {
  margin-right: 10vw;
  margin-left: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.gamesPage {
  min-height: 100vh; /* Ensure the gamesPage fills the height of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* src/App.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.becomeText {
  margin-top: 0;
}

.signUpForm .formGroup {
  margin-bottom: 15px;
}

.signUpForm .submitButton {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.signUpForm .submitButton:hover {
  background-color: #45a049;
}


.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(16, 68, 12);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.formGroup {
  margin-bottom: 15px;
  width: 100%;
  color: rgb(136, 232, 136);
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submitButton {
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: grey;
}

.FeaturedSection {
  width: 80vw;
  font-family: 'slkscr', sans-serif;
  color: rgb(78, 21, 78);
  border: 4px solid #ffe1fd;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  background-color: rgba(255, 225, 253, 0.3);
}

.FeaturedTitle {
  margin: 0;
  padding: 0;
  font-family: 'slkscr', sans-serif;
}

.FeaturedDescription {
  margin: 10px 0;
  line-height: 1.6;
  font-family: 'slkscr', sans-serif;
}



.Screenshots {
  display: flex;
  flex-direction: column; /* Stack images vertically */
  overflow-x: scroll; /* Scroll horizontally if needed */
  padding: 10px 10px;
  align-items: center; /* Align items to the start to prevent stretching */
  max-height: 100vh; /* Optional: Limit the height to the viewport */
}

.Screenshot {
  margin-bottom: 5%;
  height: auto;
  margin-right: 10px;
  border-radius: 5px;
  height: 150px; /* Adjust as needed */
}


.PlayStoreLink {
  display: inline-block;
  margin-top: 20px;
}

.homePageButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PlayStoreButton {
  background-color: #205f5b; /* Google Play green color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'slkscr', sans-serif;
  transition: background-color 0.3s;
}

.PlayStoreButton:hover {
  background-color: #2a9d3f;
}

.PlayOnlineButton {
  background-color: #205f5b; /* Google Play green color */
  margin-top: 15px;
  width: 50%;
  text-align: center;
  padding: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'slkscr', sans-serif;
  transition: background-color 0.3s;
}



.Banner {
  margin-top: 200px;
}