/* ContactStyles.css */

.ContactSection {
  width: 90%;
  z-index: 1;
  max-width: 800px;
  position: relative;
  top: 150px; /* Adjust this value */
  margin-left: auto;
  margin-right: auto;
  padding: 100px 20px 20px;
  border: 4px solid #ffe1fd;
  border-radius: 10px;
  background-color: rgba(255, 225, 253, 0.4);
}

  
  .ContactTitle {
    font-family: 'slkscr', sans-serif;
    color: rgb(78, 21, 78);
  }
  
  .ContactDescription {
    margin: 10px 0;
    line-height: 1.6;
    font-family: 'slkscr', sans-serif;
  }
  
  .ContactForm {
    display: flex;
    flex-direction: column;
  }
  
  .FormLabel {
    font-family: 'slkscr', sans-serif;
    color: rgb(78, 21, 78);
    margin-bottom: 10px;
  }
  
  .FormInput, .FormTextarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #ffe1fd;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
  }
  
  .FormButton {
    background-color: #3cba54; /* Google Play green color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'slkscr', sans-serif;
    transition: background-color 0.3s;
  }
  
  .FormButton:hover {
    background-color: #2a9d3f;
  }
  
  .ContactInfo {
    margin-top: 40px;
  }
  
  .InfoTitle {
    font-family: 'slkscr', sans-serif;
    color: rgb(78, 21, 78);
  }
  
  .InfoDescription {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: rgb(78, 21, 78);
    line-height: 1.6;
  }
  