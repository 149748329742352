/* NavbarMenu.css */

.navbar {
  position: absolute;
  background-color: #333;
  border-radius: 10px;
  padding: 3px 10px 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.burger-menu {
  cursor: pointer;
  font-size: 24px;
  color: rgb(238, 200, 241);
  user-select: none;
  margin-bottom: 5px;
}

.menu {
  list-style: none;
  border-radius: 10%;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute; /* Make the menu absolute */
  top: 50px; /* Adjust as needed to position below burger-menu */
  left: 0;
  width: 200px; /* Adjust width as needed */
  background-color: #333;
  flex-direction: column;
  z-index: 2000; /* Ensure it appears above other content */
}

.menu.open {
  display: flex;
}

.menu.closed {
  display: none;
}

.menu li {
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.menu li a {
  color: white;
  text-decoration: none;
  display: block;
}

.menu li a:hover {
  background-color: #575757;
}

.ListItem {
  display: block;
  padding: 10px;
}

.selected-item {
  color: white;
  padding: 10px;
}
